/* line 16, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel {
	margin-bottom: 20px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 100%;
}

/* line 18, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel:not(.m-listing-carousel--new) {
	margin-top: 20px;
}

/* line 31, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel.is-singleslide .listing-carousel__stage {
	transition: transform 300ms;
	overflow: visible;
	white-space: nowrap;
}

/* line 37, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel.is-loading .listing-carousel__stage {
	transition: none;
}

/* line 41, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel.is-loading.goes-forward .icon.next::before,
.m-listing-carousel.is-loading.goes-backwards .icon.prev::before {
	transform: none;
	animation: spinner-spin 1.1s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, spinner-fade 1.1s linear infinite;
	left: 25%;
	border-radius: 50%;
	content: '';
	border-width: 3px;
	border-style: solid;
	border-color: rgb(var(--color-global-positive)) rgb(var(--color-global-positive)) transparent transparent;
	position: absolute;
	width: 50%;
	height: 50%;
	top: 25%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-listing-carousel.is-loading.goes-forward .icon.next::before, .is-rtl
.m-listing-carousel.is-loading.goes-backwards .icon.prev::before {
	left: auto;
	right: 25%;
}

/* line 57, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel .c-paging-extended {
	margin-bottom: 0;
	margin-top: 10px;
}

/* line 62, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel .paging__item.is-active {
	cursor: default;
}

/* line 67, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel .carousel__title, .m-listing-carousel .carousel__subtitle {
	text-align: left;
	padding: 0 20px 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .m-listing-carousel .carousel__title, .is-rtl .m-listing-carousel .carousel__subtitle {
	text-align: right;
}

/* line 73, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel .carousel__title {
	color: rgb(var(--color-global-negative));
	font-size: 1.5em;
}

/* line 85, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel .carousel__subtitle {
	font-size: 1.2em;
}

/* line 91, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.c-tabs {
	z-index: 3;
}

/* line 94, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.c-tabs h3.tabs__item--selected {
	font-size: inherit;
	line-height: inherit;
}

/* line 105, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__label-link {
	display: block;
	padding: 10px 20px;
	color: rgb(var(--color-global-negative));
	background-color: rgb(var(--color-global-title));
}

/* line 111, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__label-link:hover {
	color: rgb(var(--color-global-positive));
	background-color: #709fb3;
}

/* line 118, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__wrapper {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

/* line 123, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 20px 2px 10px;
}

/* line 132, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__wrapper.content-box__wrapper.content-box__wrapper--without-arrows {
	padding: 20px 20px 10px;
}

@media only screen and (max-width: 29.999em) {
	/* line 132, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.listing-carousel__wrapper.content-box__wrapper.content-box__wrapper--without-arrows {
		padding: 20px 10px 10px;
	}
}

/* line 141, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__wrapper .price__upon-request {
	padding-top: 0;
	white-space: nowrap;
	display: inline;
}

/* line 148, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__wrapper--vertical.content-box__wrapper {
	padding: 40px 0;
}

/* line 154, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item {
	white-space: normal;
}

/* line 163, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel:not(.is-singleslide) .is-ready .listing-carousel__item:not(.is-visible) {
	display: none;
}

/* line 167, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--static .listing-carousel__item {
	visibility: hidden;
	opacity: 0;
}

/* line 171, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--static .listing-carousel__item.is-visible {
	visibility: visible;
	opacity: 1;
}

/* line 177, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item.is-buffered {
	display: none;
}

/* line 181, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .imagebox__tag {
	line-height: 2em;
	border: 1px solid rgb(var(--color-global-border));
	background: rgb(var(--color-global-positive));
	box-sizing: border-box;
}

/* line 190, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__info {
	display: flex;
	flex-wrap: wrap;
}

/* line 194, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__info-container {
	max-height: 90px;
	padding: 20px;
	overflow: hidden;
	width: 100%;
	box-sizing: border-box;
}

/* line 202, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__info--flex-align {
	display: flex;
	align-items: center;
}

/* line 208, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__location-info {
	text-align: left;
	flex: 0 0 auto;
	order: 1;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__location-info {
	text-align: right;
}

@media only screen and (max-width: 29.999em) {
	/* line 208, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.listing-carousel__item .listings-item__location-info {
		display: inline-block;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: block;
		margin-right: 15px;
	}
}

/* line 220, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__characteristics-container {
	text-align: right;
	flex: 1 1 auto;
	position: relative;
	overflow: hidden;
	order: 2;
	min-height: 4em;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__characteristics-container {
	text-align: left;
}

/* line 230, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__characteristics-container__wrapper {
	position: absolute;
	right: 0;
	left: 0;
}

/* line 236, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__characteristics-placeholder {
	display: inline-block;
	margin: 0 0 40px 0;
}

/* line 241, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__characteristics-beds, .listing-carousel__item .listings-item__characteristics-baths, .listing-carousel__item .listings-item__characteristics-interior {
	display: inline-block;
	vertical-align: top;
}

/* line 248, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__characteristics-beds {
	margin-right: 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__characteristics-beds {
	margin-right: initial;
	margin-left: 15px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__characteristics-beds {
		margin-right: 0;
	}
}

/* line 252, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__characteristics-interior {
	margin-left: 15px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__characteristics-interior {
	margin-left: initial;
	margin-right: 15px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__characteristics-interior {
		margin-left: 0;
	}
}

/* line 256, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__description {
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 60px;
}

/* line 262, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__description-last {
	margin-left: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__item .listings-item__description-last {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__description-last {
		margin-left: 0;
	}
}

/* line 266, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__text-price {
	padding-bottom: 0;
	padding-right: 20px;
}

/* line 270, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__text-price .c-price.is-expanded {
	display: inline-block;
}

/* line 275, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__price {
	white-space: nowrap;
	font-size: 1.125em;
}

/* line 282, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__text-location {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	font-family: var(--font-family-headings);
	color: #86bdd3;
	padding-bottom: 0;
	font-display: swap;
	font-size: 1.125em;
}

@media only screen and (min-width: 80em) {
	/* line 294, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.listing-carousel__item .listings-item__text-location.listings-item__text-location--right {
		text-align: right;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .listing-carousel__item .listings-item__text-location.listings-item__text-location--right {
		text-align: left;
	}
}

/* line 300, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listings-item__text-location.listings-item__text-location--shrink {
	width: 180px;
	margin-bottom: 10px;
}

/* line 307, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .price__currency,
.listing-carousel__item .price__value,
.listing-carousel__item .c-price--default .price__value,
.listing-carousel__item .listing-item__feat__description {
	font-size: 1.125em;
}

/* line 318, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listing-item__feat__value {
	font-size: 1.5em;
}

/* line 325, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__item .listing-item__feat__value, .listing-carousel__item .listing-item__feat__description {
	color: rgb(var(--color-global-helper));
}

/* line 333, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action {
	top: 50%;
	transform: translate(-0, -50%);
	z-index: 3;
	font-size: 14px;
	font-size: 1rem;
	position: absolute;
	overflow: hidden;
}

/* line 340, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--left {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--left {
	left: auto;
	right: 0;
}

/* line 344, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--right {
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--right {
	right: auto;
	left: 0;
}

/* line 350, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action .o-imagenav {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 354, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action .o-imagenav:hover {
	background-color: transparent;
	color: rgb(var(--color-global-primary));
}

/* line 360, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--top {
	top: 0;
}

/* line 364, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--bottom {
	bottom: -5px;
	top: auto;
}

/* line 369, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--vertical {
	left: 50%;
	transform: translate(-50%, -0);
}

/* line 373, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--vertical .imagenav--simple .imagenav__action--prev {
	left: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--vertical .imagenav--simple .imagenav__action--prev {
	left: auto;
	right: 0;
}

/* line 377, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__action--vertical .imagenav--simple .imagenav__action--next {
	right: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__action--vertical .imagenav--simple .imagenav__action--next {
	right: auto;
	left: 0;
}

/* line 384, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__slides {
	z-index: 2;
	left: 0;
	position: relative;
	top: 0;
	width: 100%;
	height: 100%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .listing-carousel__slides {
	left: auto;
	right: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 398, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-listing-carousel--vertical .listing-carousel__slides--single-row > .grid {
		overflow: hidden;
		white-space: nowrap;
	}
}

/* line 404, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--vertical .listing-carousel__slides--single-row .price__value {
	line-height: 1em;
}

/* line 410, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--horizontal:not(.is-singleslide) .listing-carousel__slides--single-row > .grid {
	overflow: hidden;
	white-space: nowrap;
}

/* line 416, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__slides--overflow {
	overflow-x: hidden;
}

/* line 421, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.property-carousel__fixed-action {
	right: 0;
	z-index: 3;
	position: absolute;
	top: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .property-carousel__fixed-action {
	right: auto;
	left: 0;
}

/* line 429, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.listing-carousel__button-wrapper {
	margin: 2px 0;
}

/* line 448, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel {
	background: transparent;
}

/* line 451, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .o-imagenav {
	background: transparent;
}

/* line 455, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .carousel__caption-button {
	font-size: 0.9em;
	letter-spacing: 2px;
	color: rgb(var(--color-global-positive));
	margin-bottom: 20px;
	text-transform: uppercase;
	display: inline-block;
	padding-left: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 455, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__caption-button {
		font-size: 0.9em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 455, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__caption-button {
		font-size: 0.9em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 455, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__caption-button {
		font-size: 0.9em;
	}
}

/* line 464, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .carousel__caption-button .icon {
	font-size: 0.7em;
}

@media only screen and (max-width: 47.999em) {
	/* line 464, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__caption-button .icon {
		font-size: 0.7em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 464, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__caption-button .icon {
		font-size: 0.7em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 464, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__caption-button .icon {
		font-size: 0.7em;
	}
}

/* line 469, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .carousel__container iframe {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@media only screen and (min-width: 60em) {
	/* line 477, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .carousel__container {
		border-left: 20px solid rgb(var(--color-global-positive));
		border-right: 20px solid rgb(var(--color-global-positive));
	}
}

/* line 484, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 0;
}

/* line 488, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .imagebox__tag {
	position: absolute;
	bottom: 70px;
	left: 2px;
	color: rgb(var(--color-global-positive));
}

/* line 495, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .listings-item__text-location {
	margin-bottom: 10px;
}

/* line 500, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .listings-item__text-location .locality {
	font-size: 3em;
	font-family: var(--font-family-headings);
	font-display: swap;
}

@media only screen and (max-width: 47.999em) {
	/* line 500, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality {
		font-size: 1.65em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 500, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality {
		font-size: 2.499em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 500, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality {
		font-size: 3em;
	}
}

/* line 505, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
	font-size: 2em;
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 505, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
		font-size: 1.1em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 505, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
		font-size: 1.666em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 505, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listings-item__text-location .locality.listings-item__text-location-title {
		font-size: 2em;
	}
}

/* line 511, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .is-next-left,
.m-carousel--videocarousel .is-next-right {
	top: 0;
}

/* line 516, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .carousel__container iframe,
.m-carousel--videocarousel .carousel__container .imagebox__tag {
	z-index: 3;
	opacity: 100%;
	transition: opacity 0.5s linear;
	transition-delay: 0.5s;
}

/* line 524, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .carousel__container:not(.is-center) iframe,
.m-carousel--videocarousel .carousel__container:not(.is-center) .imagebox__tag {
	opacity: 0;
}

/* line 529, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .listing-carousel__more-link-wrapper {
	text-align: center;
	margin-bottom: 2px;
}

/* line 533, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-carousel--videocarousel .listing-carousel__more-link {
	font-size: 1.5em;
	padding: 10px 20px;
	border-radius: 10px;
	background: rgb(var(--color-global-negative));
}

@media only screen and (max-width: 47.999em) {
	/* line 533, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listing-carousel__more-link {
		font-size: 1.05em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 533, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listing-carousel__more-link {
		font-size: 1.2495em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 533, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-carousel--videocarousel .listing-carousel__more-link {
		font-size: 1.5em;
	}
}

/* line 542, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .listing-carousel__tabs {
	flex: 1 1 auto;
	align-self: end;
}

/* line 547, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .carousel__title {
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 0 1 250px;
	padding: 0 20px;
	align-self: center;
	color: #92a2bf;
	font-size: 16px;
	font-weight: 600;
}

/* line 566, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .top-navigation {
	text-align: right;
	flex: 0 1 250px;
}

/* line 571, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .mobile-tabs {
	flex: 1 1 0;
	align-self: center;
	margin-right: 10px;
}

/* line 577, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .listing-carousel__action--right {
	margin-right: 20px;
}

/* line 581, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .listing-carousel__action {
	position: relative;
	top: auto;
	left: auto;
	bottom: auto;
	right: auto;
	transform: none;
	display: inline-block;
	margin-top: 8px;
	margin-left: 5px;
	background: #fff;
	border-radius: 5px;
	width: 30px;
	height: 30px;
}

/* line 596, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .listing-carousel__action .o-imagenav {
	font-size: 1em;
	width: 30px;
	height: 30px;
}

/* line 603, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .c-tabs {
	margin-bottom: 0;
}

/* line 607, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .tabs__item {
	border: 1px solid #ccc;
	background: #f7f7f7;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	font-size: 10px;
	font-weight: 600;
	color: #58585a;
	width: 100%;
	max-width: 175px;
	padding: 10px;
}

/* line 626, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .tabs__item--selected {
	font-size: 10px;
}

/* line 636, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .tabs__item.tabs__item--selected {
	border-bottom-color: rgb(var(--color-global-positive));
	background: rgb(var(--color-global-positive));
	color: #000c5b;
}

/* line 642, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .top-background {
	display: flex;
	width: 100%;
	background: #e8e8e8;
	height: 45px;
}

/* line 649, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .scroll-carousel__control.scroll-carousel__control--left,
.m-listing-carousel--new .scroll-carousel__control.scroll-carousel__control--right {
	display: none;
}

/* line 654, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .selectboxit-btn, .m-listing-carousel--new.selectboxit-enabled:hover {
	background: transparent;
	border: 0;
	font-weight: bold;
	font-size: 12px;
	color: #8d8d8d;
	text-align: right;
	text-transform: uppercase;
}

/* line 665, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .selectboxit-btn.selectboxit-enabled:hover .selectboxit-default-arrow {
	border-top-color: rgb(var(--color-global-secondary));
}

/* line 669, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .c-map-pagination {
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* line 676, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .c-map-pagination .bullet {
	margin: 0 2px;
}

/* line 680, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .c-map-pagination .bullet::after {
	display: block;
	content: "";
	width: 6px;
	height: 6px;
	border-radius: 100%;
	background-color: #999;
}

/* line 689, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .c-map-pagination .bullet.current::after {
	background-color: #070303;
}

@media only screen and (min-width: 60em) {
	/* line 696, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-listing-carousel--new .bullet-navigation,
	.m-listing-carousel--new .mobile-tabs {
		display: none !important;
	}
}

@media only screen and (max-width: 59.999em) {
	/* line 704, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-listing-carousel--new .top-navigation,
	.m-listing-carousel--new .listing-carousel__tabs {
		display: none !important;
	}
	/* line 709, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
	.m-listing-carousel--new .listing-carousel__slides {
		max-height: initial !important;
	}
}

/* line 714, scss/80-themes/Saunders/70-modules/propertycarousel/module.scss */
.m-listing-carousel--new .listing-carousel__wrapper.content-box__wrapper:not(.content-box__wrapper--without-arrows) {
	padding: 20px 20px 10px;
	background-color: rgb(var(--color-global-positive));
}

/*# sourceMappingURL=../../../../../true */